import React, { useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';

import { useFormData } from 'src/hook/useFormData';
import { Alert, Autocomplete } from '@material-ui/lab';
import QrComponent from './qrComponent';
import { green } from '@material-ui/core/colors';
import kbank from '../assets/images/kbank.png';
import wisdom from '../assets/images/wisdom.png';
import scan_me from '../assets/images/scan_me.png';
import scan_me_wisdom from '../assets/images/scan_me_wisdom.png';
import html2canvas from 'html2canvas';
import QRCode from 'react-qr-code';

const MainComponent = () => {
  const [channel, setChannel] = useState('Branch2');

  const {
    boothList,
    qrCode,
    empName,
    stateQr,
    branch,
    code,
    typeCode,
    booth,
    istemplate,
    error,
    handleChange,
    handleClear,
    handleChangeBranch,
    handleChangeBooth,
    handleSubmitBranch,
    handleSubmitWisdom,
    handleSubmitBooth,
    handleSubmitSpoke,
  } = useFormData();

  const handleChangeChannel = (event) => {
    handleClear();
    setChannel(event.target.value);
  };

  const handleSubmit = (event, type) => {
    event.preventDefault();
    if (type === 'Branch2') {
      handleSubmitBranch(event, type);
    } else if (type === 'Wisdom2') {
      handleSubmitWisdom(event, type);
    } else if (type === 'FXBooth2') {
      handleSubmitBooth(event, type);
    } else if (type === 'Spoke') {
      handleSubmitSpoke(event, type);
    }
  };

  const TextEllipsis = ({ text, length, type = '' }) => {
    return (
      <Grid className="empName">
        {/* {text.length > length ? text.substring(0, length) + "..." : text} */}
        {text.length > length || type === 'wisdom' ? (
          <Grid className="text-sm">{text}</Grid>
        ) : (
          text
        )}
      </Grid>
    );
  };

  const DrawTemplate = ({ type }) => {
    if (type === 'Branch2') {
      return (
        <>
          <Grid className="logo">
            <img src={kbank} alt="logo" />
          </Grid>
          <Grid className="text">
            กรุณาให้ข้อเสนอแนะและประเมิน
            <br />
            ความพอใจในการใช้บริการ
          </Grid>
          <Grid className="border-qr">
            <Grid className="qr">
              <QRCode className="img-qr" value={qrCode} />
            </Grid>
            <TextEllipsis text={empName} length={30} />
          </Grid>
          <Grid className="scan-me">
            <img src={scan_me} alt="scan me" />
          </Grid>
        </>
      );
    } else if (type === 'Wisdom2') {
      return (
        <>
          <Grid className="logo">
            <img src={wisdom} alt="logo" />
          </Grid>
          <Grid className="text wisdom">
            กรุณาให้ข้อเสนอแนะและประเมิน
            <br />
            ความพอใจในการใช้บริการ
          </Grid>
          <Grid className="border-qr wisdom">
            <Grid className="qr">
              <QRCode className="img-qr" value={qrCode} />
            </Grid>
            <TextEllipsis text={empName} length={30} type={'wisdom'} />
          </Grid>
          <Grid className="scan-me">
            <img src={scan_me_wisdom} alt="scan me" />
          </Grid>
        </>
      );
    } else if (type === 'FXBooth2') {
      return (
        <>
          <Grid className="template-fxbooth">
            <Grid className="qr">
              <QRCode className="img-qr" value={qrCode} />
            </Grid>
            <Grid className="empName">สาขา: {empName}</Grid>
          </Grid>
        </>
      );
    } else if (type === 'Spoke') {
      return (
        <>
          <Grid className="template-spoke">
            <Grid className="qr">
              <QRCode className="img-qr" value={qrCode} />
            </Grid>
            <Grid className="empName">สาขา: {empName}</Grid>
          </Grid>
        </>
      );
    }
  };

  const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const branchList = [
    { value: '0068', label: 'สาขาไอคอนสยาม' },
    { value: '1233', label: 'K-Med Club มหาวิทยาลัยเชียงใหม่' },
    { value: '1234', label: 'ศูนย์บริการเดอะวิสดอม พาราไดซ์ พาร์ค 2' },
    { value: '1235', label: 'ศูนย์บริการเดอะวิสดอม สยามพารากอน' },
    { value: '0748', label: 'สาขาเซ็นทรัล พระรามที่ 3' },
    { value: '0758', label: 'สาขาเซ็นทรัล ปิ่นเกล้า' },
    { value: '0604', label: 'สาขาเซ็นทรัล บางนา' },
    { value: '0950', label: 'สาขาเอ็มควอเทียร์' },
    { value: '0787', label: 'สาขาเซ็นทรัลเอ็มบาสซี' },
  ];

  const branchSpokeList = [
    { value: '7914', label: 'ถนนพระรามที่ 3' },
    { value: '7951', label: 'ถนนแจ้งวัฒนะ' },
    { value: '7917', label: 'ถนนเสือป่า' },
    { value: '7958', label: 'คลอง 2 ธัญบุรี' },
    { value: '7926', label: 'ถนนพระรามที่ 2' },
    { value: '7939', label: 'ลาดพร้าว 25' },
    { value: '7966', label: 'ถนนศรีนครินทร์ กม9' },
    { value: '7945', label: 'ถนนรัชดาภิเษก (สุขุมวิท-พระรามที่4)' },
    { value: '7969', label: 'สมุทรปราการ' },
    { value: '7980', label: 'แหลมฉบัง' },
    { value: '7913', label: 'ถนนสาทร' },
    { value: '7960', label: 'ถนนท่าแพ เชียงใหม่' },
    { value: '7986', label: 'ถนนจอมพล นครราชสีมา' },
    { value: '7955', label: 'นวนคร' },
    { value: '7929', label: 'อ้อมใหญ่' },
    { value: '7940', label: 'บางโพ' },
    { value: '7948', label: 'ถนนพัฒนาการ 20' },
    { value: '7946', label: 'กล้วยน้ำไท' },
    { value: '7963', label: 'ลาดกระบัง' },
    { value: '7976', label: 'นิคมฯ อมตะนคร ชลบุรี' },
    // phase 3 RC Code
    { value: '7630', label: 'ถนนรามอินทรา' },
    { value: '7740', label: 'สุขุมวิท 33 (บางกะปิ)' },
    { value: '7720', label: 'สุขุมวิท 101' },
    { value: '7730', label: 'ถนนเพชรบุรี 17' },
    { value: '7840', label: 'ตลาดนิคมอุตสาหกรรมบางปู' },
    { value: '7890', label: 'ถนนกิ่งแก้ว สมุทรปราการ' },
    { value: '7770', label: 'บางพลี' },
    { value: '7210', label: 'สุขุมวิท 107' },
    { value: '7690', label: 'หัวหมาก' },
    { value: '7610', label: 'นิคมอุตสาหกรรม 304' },
    { value: '7830', label: 'พัทยากลาง' },
    { value: '7540', label: 'นิคมฯ อีสเทิร์นซีบอร์ด ระยอง' },
    { value: '7432', label: 'มาบตาพุด' },
    { value: '7660', label: 'นิคมฯ อมตะซิตี้ (ระยอง)' },
    { value: '7670', label: 'บ้านบึง' },
    { value: '7220', label: 'สีลม' },
    { value: '7810', label: 'สุรวงศ์' },
    { value: '7700', label: 'สะพานเหลือง' },
    { value: '7250', label: 'ดาวคนอง' },
    { value: '7580', label: 'พระประแดง' },
    { value: '7790', label: 'ถนนรัตนาธิเบศร์ บางใหญ่' },
    { value: '7530', label: 'ภูเก็ต' },
    { value: '7900', label: 'วังบูรพา' },
    { value: '7870', label: 'บางลำภู' },
    { value: '7590', label: 'บางยี่ขัน' },
    { value: '7209', label: 'ตลาดเมืองทองเจริญศรี อุดรธานี' },
    { value: '7510', label: 'สวนอุตสาหกรรมโรจนะ' },
    { value: '7880', label: 'ปทุมธานี' },
    { value: '7420', label: 'ถนนเพชรเกษม หาดใหญ่' },
    { value: '7850', label: 'สุราษฎร์ธานี' },
    { value: '7650', label: 'ถนนเสรีไทย 46 (สวนสยาม)' },
    { value: '7600', label: 'เพชรเกษม 51' },
    { value: '7860', label: 'ถนนเอกชัย สมุทรสาคร' },
    { value: '7251', label: 'ถนนเศรษฐกิจ 1 สมุทรสาคร' },
    { value: '7680', label: 'บ้านโป่ง' },
    { value: '7938', label: 'พหลโยธิน' },
    { value: '7930', label: 'เอนเนอร์ยี่ คอมเพล็กซ์ (ปตท.)' },
    { value: '7820', label: 'บางซื่อ' },
  ];

  const handleDownload = (name, channel) => {
    const createDiv = document.getElementById('templateQR');
    createDiv.style.display = 'block';
    createDiv.style.width = '1241px';
    createDiv.style.height = '1754px';
    const codeName = typeCode === 'Employee Code' ? `${code}` : `3${code}`;

    // if (istemplate) {
    html2canvas(createDiv, {
      scale: 2, // Increase scale for higher resolution
      useCORS: true, // Enable CORS if necessary
    }).then((canvas) => {
      const link = document.createElement('a');
      link.download = `${codeName}_${name}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
    });

    createDiv.style.display = 'none';
    createDiv.style.width = '0px';
    createDiv.style.height = '0px';
    // }
  };

  return (
    <>
      <Grid container className="containerQR">
        <Grid item xs={12} md={7} lg={8} className="section-left-gen-QR">
          <Card>
            <form onSubmit={(event, type) => handleSubmit(event, channel)}>
              <InputLabel>
                Select Channel <span style={{ color: '#D32F2F' }}>*</span>
              </InputLabel>
              <Select
                id="Channel"
                name="channel"
                value={channel}
                required={true}
                onChange={handleChangeChannel}
                variant="outlined"
                placeholder="Select Channel"
                fullWidth
                style={{ marginBottom: '1.8rem' }}
              >
                <MenuItem value="Branch2">Branch Optional</MenuItem>
                <MenuItem value="Wisdom2">Wisdom Lounge Optional</MenuItem>
                <MenuItem value="FXBooth2">FX Booth Optional</MenuItem>
                <MenuItem value="Spoke">Spoke Optional</MenuItem>
              </Select>
              {channel === 'Branch2' && (
                <Grid container spacing={2}>
                  <Grid key="typeCode" item xs={12} md={12}>
                    <InputLabel>
                      Generate QR Survey for{' '}
                      <span style={{ color: '#D32F2F' }}>*</span>
                    </InputLabel>
                    <RadioGroup
                      aria-label="typeCode"
                      name="typeCode"
                      value={typeCode}
                      onChange={(event, type) =>
                        handleChange(event, 'TypeCode')
                      }
                    >
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            value="Employee Code"
                            control={<GreenRadio />}
                            label="Employee Code"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            value="Branch Code"
                            control={<GreenRadio />}
                            label="Branch Code"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Grid>

                  {/* <TextField id="empName" name="empName" placeholder="Enter Name" variant="outlined" fullWidth onChange={(event, type) => handleChangeCode(event, 'Codes')} /> */}

                  <Grid key={'Code'} item xs={12} md={12}>
                    <TextField
                      id={'Code'}
                      name={'Code'}
                      value={code}
                      placeholder={'Enter Code'}
                      required={true}
                      variant={'outlined'}
                      fullWidth
                      onChange={(event, type) => handleChange(event, 'Code')}
                    />
                  </Grid>
                </Grid>
              )}
              {channel === 'Wisdom2' && (
                <Grid container spacing={2}>
                  <Grid key="Branch" item xs={12} md={12}>
                    <InputLabel>
                      Select Branch <span style={{ color: '#D32F2F' }}>*</span>
                    </InputLabel>
                    <Autocomplete
                      id="wisdom-select"
                      options={branchList}
                      getOptionLabel={(option) => option.label} // <--Error message if .year is used
                      fullWidth
                      onChange={(event, data) =>
                        handleChangeBranch(event, data)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          value={branch}
                          name="Branch"
                          variant="outlined"
                          required={true}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              {channel === 'FXBooth2' && (
                <Grid container spacing={2}>
                  <Grid key="Booth" item xs={12} md={12}>
                    <InputLabel>
                      Select Booth <span style={{ color: '#D32F2F' }}>*</span>
                    </InputLabel>
                    <Autocomplete
                      id="fxbooth-select"
                      options={boothList}
                      getOptionLabel={(option) => option.boothName} // <--Error message if .year is used
                      fullWidth
                      onChange={(event, data) => handleChangeBooth(event, data)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          value={booth}
                          name="Booth"
                          variant="outlined"
                          required={true}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              {channel === 'Spoke' && (
                <Grid container spacing={2}>
                  <Grid key="Branch" item xs={12} md={12}>
                    <InputLabel>
                      Select Branch <span style={{ color: '#D32F2F' }}>*</span>
                    </InputLabel>
                    <Autocomplete
                      id="branch-select"
                      options={branchSpokeList}
                      getOptionLabel={(option) => option.label} // <--Error message if .year is used
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      fullWidth
                      onChange={(event, data) =>
                        handleChangeBranch(event, data)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          value={branch}
                          name="Branch"
                          variant="outlined"
                          required={true}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid key={'isTemplate'} item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={istemplate}
                        onChange={(event, type) =>
                          handleChange(event, 'isTemplate')
                        }
                        name={'isTemplate'}
                      />
                    }
                    value={istemplate}
                    label={'Generate QR Survey with template'}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Generate QR CODE
                  </Button>
                  {error && <Alert severity="error">{error}</Alert>}
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
        <Grid item xs={12} md={5} lg={4} className="section-right-gen-QR">
          <Card>
            <QrComponent
              name={empName}
              qrCode={qrCode}
              stateQr={stateQr}
              istemplate={istemplate}
              handleDownload={() => handleDownload(empName, channel)}
            />
          </Card>
        </Grid>
      </Grid>

      <Grid id="templateQR" data-channel={channel}>
        {istemplate ? (
          <DrawTemplate type={channel} />
        ) : (
          <>
            <Grid className="border-qr not-template">
              <Grid className="qr">
                <QRCode className="img-qr" value={qrCode} />
              </Grid>
              <Grid className="empName">{empName}</Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default MainComponent;
